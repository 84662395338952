@import "../main/abstracts/index";

.top-topics .col, .top-topics [class*=col-]{
    display: flex;
    flex-direction: column;
}
.top-topics.row {
    gap: var(--grid-gutter-width, 2rem) 0;
}
.top-topics__item{
    position: relative;
    background-color: $color__gray-custom;
    display: flex;
    flex-direction: column;
    justify-content: flex;
    flex: 1;
    h3{
        @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 26px);
        font-weight: $font__weight--normal
    }
    .top-topics__pretitle{
        @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 18px);
        color: $color__primary;
        font-weight: 500;
    }
    &--with-link{
        .top-topics__caption{
            a{
                text-decoration: none;
                color: $color__font;
                font-weight: 400;

                &::before{
                    display: none;
                }
            }

        }
        &.top-topics__item--with-image{
            .top-topics__caption--arrow{
                @include transition;

                img{
                    @include transition;

                }
                &:before{
                    @include transition;

                }
            }
            .top-topics__caption{
                overflow: hidden;
            }
            @include hover-focus-within{
                .top-topics__image{
                    transform: scale(1.1);
                }
                .top-topics__caption--arrow{
                    @include transition;
                    background-color: $color__primary;
                    img{
                        @include transition;
                        filter: $filter__white
                    }
                    &:before{
                        @include transition;
                        background-color: $color__white;
                    }
                }
            }
            .top-topics__image{
                @include transition;
            }
        }
    }
    &--with-image{
        overflow: hidden;
        .top-topics__caption{
            @include media-breakpoint-up(md) {
                margin: 0px 34px 34px;
            }
            margin: 0px 15px 15px;
            background-color: $color__white;
            display: flex;
            align-items: center;
            flex-direction: row;

            border-radius: 8px;
            &--content{
                flex: 1;
                padding: 25px;
            }
            &--arrow{
                height: 100%;
                position: relative;
                display: flex;
                align-items: center;
                padding: 0 25px;
                &:before{
                    content: "";
                    width: 1px;
                    height: 100%;
                    background-color: rgba(#8F98AB,0.25);
                    position: absolute;
                    left: 0;
                    top: 0;
                }

            }
        }
    }
    &.top-topics__item--2col{
        &.top-topics__item--with-image{
            &:before{
                content: "";
                padding-top: 82%;
                width: 100%;
                @include media-breakpoint-up(lg) {
                    content: "";
                    padding-top: 40%;
                    width: 100%;
                }


            }
            .top-topics__caption{
                z-index: 1;
                @include media-breakpoint-up(lg) {
                    width: calc(50% - 34px - 34px - (var(--bs-gutter-x)*.5) );
                }
                width: unset;
            }
            .top-topics__image{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                figure,picture,img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    &.top-topics__item--1col{
        &:not(.top-topics__item--with-image){

            .top-topics__caption{
                padding: 15px 15px 30px 15px;
                @include media-breakpoint-up(md) {
                    padding: 30px 30px 60px 30px;
                }
                @include media-breakpoint-up(lg) {
                    padding: 60px 60px 90px 60px;
                }
                p{
                    color: $color__font;
                    // font-size: 16px;
                    line-height: 1.3;
                    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 18px);
                    +a.button{
                        margin-top: 30px;
                        color: $color__white;
                        font-size: 20px;
                        font-weight: 700;
                    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 22px);
                    }
                }
                .top-topics__pretitle{
                    color: $color__primary;
                }
            }
            @include hover-focus-visible{
                a.button{
                    background-color:$color__primary--hover;
                    
                }
            }
        }
        &.top-topics__item--with-image{
            &:before{
                content: "";
                padding-top: 82%;
                width: 100%;

            }
            .top-topics__caption{
                z-index: 1;
            }
            .top-topics__image{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                figure,picture,img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
